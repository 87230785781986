<template>
  <div class="hall-notice">
    <div class="notice-content">
      <icon icon-name="ChatDotSquare" :icon-title="title"></icon>
    </div>
  </div>
</template>

<script>
import icon from '@/components/icon'

export default {
  name: 'Notice',
  components: {
    icon
  },
  data() {
    return {
      title: '2021/10/24: 编辑器增加图片预览、图片上传等功能! 优化部分细节问题!'
    }
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped>
.hall-notice {
  width: 100%;
  padding: 20px;
  color: #969696;
  position: relative;
  display: inline-block;
  background: #fbfbfb50;
  font-size: 15px;
  overflow: hidden;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px dashed #e6e6e6;
}

.notice-content {
  width: 95%;
  vertical-align: middle;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  -webkit-animation: 4s changeColor infinite;
  animation: 4s changeColor infinite;
}

@keyframes changeColor {
  0% {
    color: #f96262;
  }

  50% {
    color: orange;
  }

  100% {
    color: yellowgreen;
  }
}
</style>
