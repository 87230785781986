<template>
  <div class="hall-primary">
    <div class="primary-title">
      <h1>
        <icon icon-name="Flag" icon-title="繁星点点 (✿◠‿◠)~" icon-space="5"></icon>
      </h1>
    </div>
    <div class="primary-list">
      <div
        class="primary-content"
        v-for="(item, index) in pushData"
        :key="index"
        @click="queryDetail(item.serial)"
      >
        <div class="left-content" v-if="index % 2 === 1">
          <el-image
            :src="
              item.coverUrl == null
                ? require('@/assets/default/default_primary.webp')
                : item.coverUrl
            "
            lazy
            fit="cover"
          ></el-image>
        </div>
        <div class="right-content">
          <div class="content-date">
            <icon icon-name="Timer" :icon-title="'发布于 ' + item.pushTime" icon-space="5"></icon>
          </div>
          <h3 class="content-title">{{ item.title }}</h3>
          <div class="content-tags">
            <span v-for="(tagName, index) in item.tags.split(',')" :key="index" :serial="tagName">
              {{ tagName }}
            </span>
          </div>
          <div class="content-desc">{{ item.notesDesc }}</div>
        </div>
        <div class="left-content" v-if="index % 2 === 0">
          <el-image
            :src="
              item.coverUrl == null
                ? require('@/assets/default/default_primary.webp')
                : item.coverUrl
            "
            lazy
            fit="cover"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="primary-more" v-if="pushData.length > 0">
      <a @click="loadMore">更 多</a>
    </div>
  </div>
</template>

<script>
import icon from '@/components/icon'
import { notesPushPage } from '@/api/ArticleApi'

export default {
  name: 'Primary',
  components: {
    icon
  },
  data() {
    return {
      pushData: [],
      currentPage: 1
    }
  },
  methods: {
    queryDetail(serial) {
      this.$router.push({
        name: 'HallDetail',
        query: {
          serial: serial
        }
      })
    },
    loadNotesPush() {
      notesPushPage({
        pageNum: this.currentPage
      }).then((res) => {
        if (res.code === 200) {
          this.pushData = this.pushData.concat(res.data.rows)
        }
      })
    },
    loadMore() {
      this.currentPage++
      this.loadNotesPush()
    }
  },
  mounted() {
    this.loadNotesPush()
  }
}
</script>

<style scoped>
.hall-primary {
  min-height: 300px;
}

.primary-title h1 {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  font-family: 'Ubuntu', sans-serif;
  border-bottom: 1px dashed #c8c6c6;
}

.primary-list {
  width: 100%;
  height: 100%;
}

.primary-content {
  width: 100%;
  height: 300px;
  margin: 40px 0;
  border-radius: 10px;
  transition: box-shadow 0.3s ease;
  background-color: hsla(0, 0%, 100%, 0);
  box-shadow: 0 1px 20px -6px rgb(0 0 0 / 50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.left-content {
  width: 55%;
  height: 100%;
  overflow: hidden;
}

.right-content {
  width: 40%;
  height: 100%;
  margin: 0 2.5%;
  overflow: hidden;
}

.content-date {
  width: 100%;
  height: 20%;
  color: #888;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.content-title {
  width: 100%;
  height: 9%;
  padding: 0 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.content-tags {
  margin: 4% 0;
  text-align: right;
}

.content-tags span {
  margin: 0 5px;
  color: #969696;
  font-size: 14px;
}

.content-tags span:hover {
  color: #fe9600;
}

.content-desc {
  height: 50%;
  margin-top: 4%;
  padding: 0 10px;
  overflow: hidden;
  color: #727070;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.el-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.primary-more {
  width: 100%;
  padding: 20px 0;
  text-align: center;
  margin: 40px 0 80px;
  display: inline-block;
}

.primary-more a {
  padding: 13px 35px;
  border: 1px solid #d6d6d6;
  border-radius: 50px;
  color: #adadad;
  user-select: none;
  text-decoration: none;
}

.content-title:hover {
  color: #fe9600;
}

.primary-more a:hover {
  color: #fe9600;
  box-shadow: 0 0 4px #fe9600;
  border: 1px solid #fe9600;
}

.primary-content:hover {
  box-shadow: 0 5px 10px 5px rgb(110 110 110 / 40%);
}

.primary-content:hover >>> .el-image {
  transform: scale(1.2);
}
</style>
