<template>
  <div class="hall-navigation">
    <div class="nav-title">
      <h1>
        <icon icon-name="Promotion" icon-title="轻风听雨 ≧◔◡◔≦" icon-space="5"></icon>
      </h1>
    </div>
    <div class="nav-list">
      <div class="nav">
        <el-image :src="require('@/assets/default/nav_list_01.webp')" fit="fill"></el-image>
        <div class="nav-info">
          <h3><span>云淡风轻</span></h3>
          <p><span>云淡风轻近午天 傍花随柳过前川</span></p>
        </div>
      </div>
      <div class="nav">
        <el-image :src="require('@/assets/default/nav_list_02.webp')" fit="fill"></el-image>
        <div class="nav-info">
          <h3><span>生如夏花</span></h3>
          <p><span>生如夏花之绚烂 死如秋叶之静美</span></p>
        </div>
      </div>
      <div class="nav">
        <el-image :src="require('@/assets/default/nav_list_03.webp')" fit="fill"></el-image>
        <div class="nav-info">
          <h3><span>雪落苍茫</span></h3>
          <p><span>雪落苍茫 万物头垂 白首瞬间</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@/components/icon'

export default {
  name: 'Navigation',
  components: {
    icon
  },
  data() {
    return {}
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped>
.nav-title h1 {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  font-family: 'Ubuntu', sans-serif;
  border-bottom: 1px dashed #c8c6c6;
}

.nav-list {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  width: 33%;
  height: 165px;
  overflow: hidden;
  border-radius: 20px;
  display: inline-block;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 30%);
}

.nav:nth-child(2) {
  margin: 0 2%;
}

.el-image {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.nav-info {
  width: 100%;
  height: 110%;
  position: relative;
  transition: all 0.5s ease-in-out;
  top: -110%;
  left: 0;
  text-align: center;
}

.nav-info h3 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  background: #111;
  position: relative;
  top: 30%;
  transition: all 0.35s ease-in-out;
  transform: translateX(-100%);
}

.nav-info p {
  position: relative;
  top: 25%;
  font-style: italic;
  font-size: 12px;
  color: #bbb;
  padding: 20px;
  text-align: center;
  transition: all 0.35s 0.1s linear;
  transform: translateX(100%);
}

.nav:hover > .nav-info {
  background: rgba(0, 0, 0, 0.6);
}

.nav:hover > .nav-info h3 {
  transform: translateX(0);
}

.nav:hover > .nav-info p {
  transform: translateX(0);
}

.nav:hover > .el-image {
  transform: scale(1.2);
}
</style>
