<template>
  <div class="common-footer">
    <span>
      <i class="icon-style">&gt;&gt;&gt;</i>
    </span>
    <span class="copyright">Copyright © 2017-2022</span>
    <span class="author">小风子.凤凰集</span>
    <span>
      <a class="beian" href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备20003943号-1</a>
    </span>
    <span>
      <i class="icon-style">&lt;&lt;&lt;</i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {}
  }
}
</script>

<style scoped>
.common-footer {
  height: 40px;
  font-size: 16px;
  color: orange;
  text-align: center;
}

.common-footer span {
  margin: 0 5px;
  letter-spacing: 1px;
  line-height: 20px;
}

.icon-style {
  font-size: 20px;
  -webkit-animation: 3s changeColor infinite;
  animation: 3s changeColor infinite;
}

.copyright:hover {
  text-shadow: 1px 1px 1px #0202ff;
}

.author:hover {
  text-shadow: 1px 1px 1px #e100ff;
}

.beian {
  color: orange;
  font-size: 16px;
  text-decoration: none;
}

.beian:hover {
  text-shadow: 1px 1px 1px #ff0000;
}

@-webkit-keyframes changeColor {
  0% {
    color: #f96262;
  }

  50% {
    color: orange;
  }

  100% {
    color: yellowgreen;
  }
}
</style>
