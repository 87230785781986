<template>
  <div class="hall">
    <common-header class="hall-head"></common-header>
    <div style="height: 80px"></div>
    <div class="hall-main">
      <div class="main-left"></div>
      <div class="main-center">
        <hall-notice style="margin-top: 50px"></hall-notice>
        <hall-navigation style="margin-top: 50px"></hall-navigation>
        <hall-primary style="margin-top: 50px"></hall-primary>
      </div>
      <div class="main-right"></div>
    </div>
    <div style="height: 50px"></div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import Notice from '@/views/hall/components/Notice'
import Navigation from '@/views/hall/components/Navigation'
import Primary from '@/views/hall/components/Primary'

export default {
  name: 'Hall',
  components: {
    'common-header': Header,
    'common-footer': Footer,
    'hall-notice': Notice,
    'hall-navigation': Navigation,
    'hall-primary': Primary
  }
}
</script>

<style scoped>
.hall {
  width: 100%;
}

.hall-head {
  position: fixed;
  z-index: 999;
  box-shadow: 0 1px 40px -8px rgb(0 0 0 / 50%);
}

.hall-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
}

.main-left {
  width: 30%;
  height: 100%;
}

.main-center {
  width: 40%;
  height: 100%;
}

.main-right {
  width: 30%;
  height: 100%;
}
</style>
